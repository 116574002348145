import Title from './components/Title';
import Header from './components/Header';
import Clients from './components/Clients';

// TODO: move to pages/home/index
function App() {
	return(
		<div>
			<Header />

			<div className="h-screen flex flex-col justify-center p-8 md:p-16">
				<Title />

				<p className="mt-5 text-white leading-relaxed text-lg">
					An independent code studio based in Sweden.<br />
					Making changes and experiences that matters.
				</p>
			</div>

			<Clients />

			<footer className="flex items-center flex-col justify-center pb-4 text-white/80">
				<img src="/SweetSweet.png" alt="SweetSweet logotype" style={{ height: 65 }} />
				All rights reserved. 2024
			</footer>
		</div>
	);
}

export default App;
