import { motion, useScroll, useTransform } from "framer-motion";

const ScrollBox = ({ children, inputRange = [0, 0], outputRange = [0, 0], style, animateIn = false, ...rest }) => {
	const { scrollY } = useScroll()
	const yValue = useTransform(scrollY, inputRange, outputRange) ?? 0;
	const opacityValue = useTransform(scrollY, inputRange, animateIn ? [0, 1] : [1, 0]);

	return <motion.div
		style={{ y: yValue, opacity: opacityValue, ...style }}
		transition={{
			type: "spring",
			stiffness: 230,
			damping: 15,
			duration: 5,
		}}
		{...rest}
	>
		{children}
	</motion.div>
};

export default ScrollBox;