import React from 'react';
import ScrollBox from './ScrollBox';

const Title = (props) => {
	const DURATION = 145;
	const OFFSET = (window.innerHeight / 4) - 100;

	return(
		<>
			<div className="font-extrabold leading-none text-white text-8xl">
				<ScrollBox
					initial={{ x: -115, opacity: 0 }}
					animate={{ x: 0, opacity: 1 }}
					inputRange={[0, DURATION / 1.5]}
					outputRange={[0, -50]}
					className="inline-block mr-6"
				>
					{'That '}
				</ScrollBox>

				<ScrollBox
					initial={{ x: -115, opacity: 0 }}
					animate={{ x: 0, opacity: 1 }}
					inputRange={[OFFSET, OFFSET + DURATION]}
					outputRange={[0, -50]}
					className="inline-block text-gray-900 text-outlined"
				>
					Sweet
				</ScrollBox>
			</div>

			<div className="font-extrabold text-white text-8xl">
				<ScrollBox
					initial={{ x: 115, opacity: 0 }}
					animate={{ x: 0, opacity: 1 }}
					// inputRange={[(OFFSET + DURATION) / 1.25, ((OFFSET + DURATION) / 2 + DURATION)]}
					inputRange={[OFFSET, OFFSET + DURATION]}
					outputRange={[0, -50]}
					className="inline-block text-gray-900 text-outlined"
				>
					Sweet
				</ScrollBox>

				<ScrollBox
					initial={{ x: 115, opacity: 0 }}
					animate={{ x: 0, opacity: 1 }}
					inputRange={[DURATION / 3, DURATION]}
					outputRange={[0, -50]}
					className="inline-block ml-6"
				>
					Spot
				</ScrollBox>
			</div>
		</>
	);
}

export default Title;
