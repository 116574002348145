import React, { Component } from 'react';
import HeaderLogo from './HeaderLogo';

class Header extends Component {

	render() {
		return(
			<div className="p-5 px-8 md:px-16 text-white w-full flex text-large fixed top-0 mix-blend-difference items-center">
				<div className="flex-1">
					<HeaderLogo />
				</div>

				<div className="flex-1 text-right">
					header
				</div>
			</div>
		);
	}

}

export default Header;
