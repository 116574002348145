import ScrollBox from "./ScrollBox"

const HeaderLogo = (props) => {
	const DURATION = 145;
	const OFFSET = (window.innerHeight / 4) - 100;

	return(
		<div>
			<ScrollBox
				animateIn
				inputRange={[OFFSET, OFFSET + DURATION]}
				outputRange={[-25, 0]}
				className="inline-block text-2xl"
			>
				Sweet
			</ScrollBox>

			<ScrollBox
				animateIn
				inputRange={[(OFFSET + DURATION) / 2, ((OFFSET + DURATION) / 2 + DURATION)]}
				outputRange={[-25, 0]}
				className="inline-block text-2xl"
			>
				Sweet
			</ScrollBox>
		</div>

	);
}

export default HeaderLogo;
