import { motion } from "framer-motion";

const Clients = () => {
	return (
		<div className="h-screen w-full p-8 md:p-16">
			<motion.div
				className="h-full w-full flex items-center"
				initial="hidden"
				whileInView="visible"
				transition={{ duration: 0.45 }}
				variants={{
					visible: { translateX: 0 },
					hidden: { translateX: '-100%' }
				}}
			>
				<div className="h-2/3 w-1/2 bg-white rounded-3xl">
				</div>
			</motion.div>
		</div>
	);
};

export default Clients;
